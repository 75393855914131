import React, { Component } from 'react'

class FloweringRisk extends Component{
    constructor(props){
        super(props);

        this.state = {
            series: []
        }
    }

    renderFloweringChart(){
        window.Highcharts.chart('flowering', {
            chart: {
                style: {
                  fontFamily: 'Roboto, sans-serif'
                }
            },
            title: {
                text: this.props.title,
                style: {
                    color: 'black',
                    fontSize: '24px',
                    fontWeight: '400'
                }
            },
            yAxis: {
                title: {
                    style: {
                        fontSize: '18px',
                        color: 'black'
                    },
                    text: `Risco de Florescimento (%)`
                },
                min: 20,
                max: 100,
                tickAmount: 9,
                gridLineColor: 'transparent',
                labels: {
                    style: {
                        fontSize: '14px',
                    }
                },
                plotBands: [{
                    color: '#6BAED6',
                    from: 20,
                    to: 30
                },{
                    color: '#74C476',
                    from: 30,
                    to: 50
                },{
                    color: '#FFFA22',
                    from: 50,
                    to: 70
                },{
                    color: '#FF8A4A',
                    from: 70,
                    to: 90
                },{
                    color: '#EF3B2C',
                    from: 100,
                    to: 90
                }]
            },
            xAxis: {
                title: {
                    style: {
                        fontSize: '17px',
                        color: 'black'
                    },
                    text: `Data da Previsão`,
                    margin: 10
                },
                labels: {
                    rotation: 270,
                    style: {
                        fontSize: '14px',
                    }
                },
                categories: this.props.dateRange,

                plotBands: [{
                    color: 'transparent',
                    borderColor: '#4f4f4f',
                    borderWidth: 2,
                    from: this.props.inductivePeriod.start,
                    to: this.props.inductivePeriod.end+1,
                    zIndex: 1
                }],
                plotLines: [{
                    color: '#4f4f4f',
                    dashStyle: 'dash',
                    width: 2,
                    value: this.props.inductivePeriod.control_line,
                    zIndex: 1,
                },{
                    color: 'rgba(0, 0, 0, 0)',
                    width: 2,
                    value: this.props.inductivePeriod.start,
                    label: {
                        text: '▲',
                        align: 'bottom',
                        x: -14,
                        y: 434,
                        style: {
                            color: '#000000',
                            fontSize: '1.4rem',
                            fontWeight: '140'
                        }
                    },
                    zIndex: 1,
                }]
            },
            tooltip: {
                enabled: this.props.showTooltips,
                headerFormat: '<span style="font-size: 1em">{point.key}</span><br/>',
                style: {
                    fontSize: '14px'
                }
            },

            series: this.state.series,

            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '15px'
                }
            },

            plotOptions: {
                line: {
                    color: 'black',
                    dashStyle: 'Dash',
                    lineWidth: 1,
                    stickyTracking: false,
                    marker: {
                        radius: 2,
                        lineWidth: 1,
                        lineColor: 'black',
                        fillColor: 'white'
                    },
                },
                boxplot: {
                    zIndex: 5,
                    tooltip: {
                        pointFormat: `<span style="color:{point.color}">●</span> <b> {series.name}</b><br/>Limite superior: {point.high} ${this.props.unit}<br/>Terceiro quartil: {point.q3} ${this.props.unit}<br/>Mediana: {point.median} ${this.props.unit}<br/>Primeiro quartil: {point.q1} ${this.props.unit}<br/>Limite inferior: {point.low} ${this.props.unit}<br/>`
                    },
                    color: '#000'
                },
                scatter: {
                  tooltip: {
                    pointFormat: `<span style="color:{point.color}">●</span> <b> {series.name}</b><br/> {point.y} ${this.props.unit}`
                  }
                }
            },

            credits: {
                enabled: false
            }
        })
    }

    seriesValidation(){

        let series;
        let ghostData = []

        this.props.dateRange.map(() => {
            return ghostData.push(0)
        })

        if(this.props.currentYear === null || (Array.isArray(this.props.currentYear) && this.props.currentYear.length === 0)){
            series = [
                {
                    name: this.props.lastYear.year,
                    data: this.props.lastYear.value
                }, {
                    name: this.props.secondToLastYear.year,
                    data: this.props.secondToLastYear.value
                }, {
                    name: this.props.thirdToLastYear.year,
                    data: this.props.thirdToLastYear.value
                }, {
                    data: ghostData,
                    showInLegend: false
                }
            ]
        }else if(this.props.currentYear.chart !== "line"){
            series = [
                {
                    type: this.props.currentYear.chart,
                    name: this.props.currentYear.year,
                    data: this.props.currentYear.data,
                    marker : ['line', 'scatter'].includes(this.props.currentYear.chart) ? {
                        radius: 5,
                        fillColor: '#ffffff',
                        lineColor: 'black',
                        lineWidth: 2,
                    }
                    :
                        undefined
                }, {
                    name: this.props.lastYear.year,
                    data: this.props.lastYear.value,
                    marker: {
                        symbol: "square",
                        fillColor: "black",
                        radius: 3
                    }
                }, {
                    name: this.props.secondToLastYear.year,
                    data: this.props.secondToLastYear.value,
                    marker: {
                        symbol: "triangle",
                        radius: 4
                    }
                }, {
                    name: this.props.thirdToLastYear.year,
                    data: this.props.thirdToLastYear.value,
                    marker: {
                        fillColor: "black",
                        radius: 3
                    }
                }, 
                {
                    data: ghostData,
                    showInLegend: false
                }
            ]
        }
        else {
            series = [
                {
                    type: this.props.currentYear.chart,
                    name: this.props.currentYear.year,
                    data: this.props.currentYear.data,
                    marker : ['line', 'scatter'].includes(this.props.currentYear.chart) ? {
                        radius: 3
                    }
                    :
                        undefined
                }, {
                    name: this.props.lastYear.year,
                    data: this.props.lastYear.value,
                    marker: {
                        symbol: "square",
                        fillColor: "black",
                        radius: 3
                    }
                }, {
                    name: this.props.secondToLastYear.year,
                    data: this.props.secondToLastYear.value,
                    marker: {
                        symbol: "triangle",
                        radius: 4
                    }
                },
                {
                    data: ghostData,
                    showInLegend: false
                }
            ]
        }

        this.setState({ series: series }, () => this.renderFloweringChart())
    }

    componentDidMount(){
        this.seriesValidation()
    }

    render(){
        return <div id='flowering' className='flowering-chart'/>
    }

}

export default FloweringRisk
