import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames'

import FloweringRisk from '../components/reports/productivity/flowering/FloweringRisk'
import DropdownField from '../components/common/DropdownField'
import { setYearsList } from '../shared/utils/DatesUtils'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { resetReport, setReportTypes, toggleReportContainer } from '../actions/reports'
import { generateMapData, changeMapDay, setGridPointName, resetGrid } from '../actions/map'
import { toggleTooltips, generatingMapData, toggleLoader } from '../actions/global'
import { getFloweringRisk, resetFloweringReport } from '../actions/flowering'

import { showToaster } from "../components/common/Toaster"
import '../stylesheets/css/components/flowering.css'

import guide from '../files/Guia_Risco_de_Florescimento_Agrymax.pdf';

const DATE = new Date();
const NEXT_YEAR = DATE.getFullYear() + 1;
const CURRENT_MONTH = DATE.getMonth() + 1
const CURRENT_DAY = DATE.getDay()

const insertNextYear  = CURRENT_MONTH === 12 && CURRENT_DAY >= 1

class FloweringContainer extends Component {
  constructor(props){
    super(props);

    this.state = {
      predictionRange: "15 dias",
      selectedYear: new Date().getFullYear(),
      currentYearData: []
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.activeFarm !== this.props.activeFarm){
      let newFarm = this.props.farms.find(farm => farm.id === this.props.activeFarm)
      if(newFarm.smart && newFarm.smart.products.indexOf("cana") > -1){
        const data = {
          farmId: this.props.activeFarm,
          predictionDays: parseInt(this.state.predictionRange.split(' ')[0], 10),
          year: this.state.selectedYear
        }
        this.props.resetFloweringReport()
        this.props.getFloweringRisk(data)
      }
      else{
        this.props.setReportTypes('');
        this.props.toggleReportUndefined();
        this.toggleActive();
        this.props.setGridPointName("")
        this.props.resetGrid()
        return
      }
    }

    if(prevProps.data === undefined
      && (this.props.data !== undefined
        && (this.props.data.current_year_data === null
          || (Array.isArray(this.props.data.current_year_data)
          && this.props.data.current_year_data.length === 0)))) {

      const toasterParams = {
        title: 'Atenção!',
        message: 'Ainda não é possível realizar o cálculo para o ano atual',
        status: false,
        autoClose: false,
      }
      showToaster(toasterParams)
    }
  }

  onDropdownChange = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    }, () => {

      const prediction = parseInt(this.state.predictionRange.split(' ')[0], 10)

      const data = {
        farmId: this.props.activeFarm,
        predictionDays: prediction,
        year: this.state.selectedYear
      }

      this.props.generatingMapData()
      this.props.generateMapData('cane-flowering', data)

      this.props.setReportTypes('cane-flowering');
      this.props.toggleReportUndefined();

      if(!this.props.reportActive){
        this.props.toggleReportContainer();
      }

      this.props.resetFloweringReport()
      this.props.getFloweringRisk(data)
    })
  }

  toggleActive = () => {
    this.props.toggleReportContainer()
  }

  render(){
    const activeFarm = this.props.farms ? this.props.farms.find(farm => farm.id === this.props.activeFarm) : null
    const farmName = activeFarm.name !== '' ? activeFarm.name : "Ponto " + activeFarm.initials
    const title = `Risco de florescimento da cana de açúcar | ` + farmName


    return(
      <Fragment>
        <section className={classnames("report--container visible productivity", {active: this.props.reportActive})} 
          style={{zIndex: "1299"}}
        >
          <span className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !this.props.reportActive})} onClick={ this.toggleActive }><i className='material-icons'>play_arrow</i></span>
          <header className='flowering-header'>
            <div className="flowering-options-container">
              <div className="flowering-dropdowns-container">
                <div className="flowering-prediction-container">
                  Previsão:
                  <DropdownField
                    name={"predictionRange"}
                    value={this.state.predictionRange}
                    options={['15 dias','30 dias']}
                    onChange={this.onDropdownChange}
                    type={'prod'}
                  />
                </div>

                <div className="flowering-prediction-container">
                  Previsão:
                  <DropdownField
                    name={"selectedYear"}
                    value={this.state.selectedYear}
                    options={insertNextYear ? [...setYearsList(1980, new Date().getFullYear()), NEXT_YEAR] : setYearsList(1980, new Date().getFullYear())}
                    onChange={this.onDropdownChange}
                    type={'prod'}
                  />
                </div>

                <a href={guide} download style={{ fontWeight: 600 }}>
                  <div
                    style={{
                      backgroundColor: '#6aa426',
                      padding: '6px',
                      borderRadius: '8px',
                      color: 'white'
                    }}
                  >
                    <span style={{ color: 'white', lineHeight: '2em' }}>
                      Guia de Risco de Florescimento
                    </span>
                    <i className='material-icons' style={{ verticalAlign: 'middle' }}>
                      download
                    </i>
                  </div>
                </a>
              </div>

              <div className="header-graphic" style={{marginRight: '4px'}}>
                <span title={this.props.showTooltips ? 'Esconder caixa de informações' : 'Exibir caixa de informações'} onClick={this.props.toggleTooltips}>
                  <i className='material-icons'>{this.props.showTooltips ? 'visibility_off' : 'visibility'}</i>
                </span>
              </div>

            </div>
          </header>
          <div className="general-prod-disclaimer">
              <span>*Os dias usados para cálculo de previsão têm efeito apenas até 21/03</span>
          </div>
          <PerfectScrollbar className={classnames('flowering-scroll ps ps--theme default')}>
            <div className='flowering-chart-container'>
              <div >
                {this.props.data ?
                  <FloweringRisk
                    title={title}
                    dateRange={this.props.data.dates}
                    unit={'%'}
                    showTooltips={this.props.showTooltips}
                    currentYear={this.props.data.current_year_data}
                    lastYear={this.props.data.previous_years_data[0]}
                    secondToLastYear={this.props.data.previous_years_data[1]}
                    thirdToLastYear={this.props.data.previous_years_data[2]}
                    inductivePeriod={this.props.data.inductive_period}
                    />
                :
                  <div className='loading-screen-flowering'>
                    Calculando...
                  </div>
                }
              </div>
            </div>

            <div className='inductive-legend'>
              <div className='inductive-period'>
                <span className='arrow'>▲</span>: Início do período indutivo
              </div>
              <div className='decision-period'>
                <span className='square'/>Período recomendado para tomada de decisão
              </div>
            </div>

            <div className='flowering-legend'>
              <div className='action-table'>
                <div className='colors'>
                  <div className='header'>Cor</div>
                  <div className='very-high'></div>
                  <div className='high'></div>
                  <div className='medium'></div>
                  <div className='low'></div>
                  <div className='very-low'></div>
                </div>

                <div className='names'>
                  <div className='header'>Risco</div>
                  <div className='very-high'>Muito Alto</div>
                  <div className='high'>Alto</div>
                  <div className='medium'>Moderado</div>
                  <div className='low'>Baixo</div>
                  <div className='very-low'>Muito Baixo</div>
                </div>

                <div className='actions'>
                  <div className='header'>Ação</div>
                  <div className='high'>Recomenda-se Inibir</div>
                  <div className='medium'>Alerta</div>
                  <div className='low'>Não Inibir</div>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        </section>
      </Fragment>
    )
  }

}

function mapStateToProps(state) {
  return {
    farms: state.farms.points.farms,
    reportActive: state.Reports.reportActive,
    activeFarm: state.farms.activeFarm,
    showTooltips: state.global.showTooltips,
    data: state.flowering.data,
    report: state.Reports.report,
  }
};

function mapDispatchToProps(dispatch){
  return {
    getFloweringRisk: (data) => {
      dispatch(getFloweringRisk(data))
    },
    resetFloweringReport: () => {
      dispatch(resetFloweringReport())
    },
    toggleTooltips: () => {
      dispatch(toggleTooltips())
    },
    setGridPointName: (name) => {
      dispatch(setGridPointName(name))
    },
    resetGrid: () => {
      dispatch(resetGrid())
    },
    setMapDay: (day) => {
      dispatch(changeMapDay(day))
    },
    generatingMapData: () => {
      dispatch(generatingMapData())
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    generateMapData: (reportTypes, attrs) => {
      dispatch(generateMapData(reportTypes,attrs))
    },
    setReportTypes: (report) => {
      dispatch(setReportTypes(report))
    },
    toggleReportUndefined: ()=>{
      dispatch(resetReport())
    },
    toggleReportContainer: () => {
      dispatch(toggleReportContainer())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FloweringContainer)
